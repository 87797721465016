<template>
    <v-container fluid>
        <v-card
            class="mx-auto"
            max-width="300"
        >
            <v-card-title
                class="primary--text secondary"
            >
                Reports
            </v-card-title>
            <v-list>                
                <v-list-item-group color="primary">
                    <template v-for="(report, i) in reports">
                        <v-list-item                            
                            :key="report.value"
                            @click="displayReportMenu(report)"
                        >
                            <v-list-item-content>
                                <v-list-item-title >
                                    {{ report.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            v-if="i < reports.length - 1"
                            :key="i"
                        >                        
                        </v-divider>
                    </template>
                    
                </v-list-item-group>
            </v-list>
        </v-card>

        <v-dialog
            v-model="dialog"
            max-width="90vw"
            persistent            
        >
            <v-card height="85vh" class="pb-0">
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    frameBorder="0" 
                    :src="src"
                ></iframe>

                <v-btn
                    dark
                    color="error"
                    small
                    fab
                    style="position:absolute; top:10px; right:10px; z-index:100"
                    @click="closeReportMenu"
                >
                    <v-icon dark>mdi-close</v-icon>
                </v-btn>
                
                <v-overlay
                    :absolute="absolute"
                    :value="overlay"
                    opacity="0.5"
                    color="#A0A0A0"
                >                
                    <v-card                        
                        light
                        :max-width="maxWidth"
                        class="pa-4"
                    >
                        <v-card-title>
                            {{ cardTitle }}
                        </v-card-title>

                        <v-card-text
                            v-if="menu.studentSubject" 
                        >
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="selectedFormLevel"
                                        :items="formLevelsUpperSchool"
                                        label="Select Form"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="selectedSubjectId"
                                        :items="subjects"
                                        item-text="title"
                                        item-value="id"
                                        label="Select Subject"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text
                            v-if="menu.reportCardAccess" 
                        >
                             <v-row>
                               
                                <v-col 
                                    cols="12"                                    
                                >
                                    <v-autocomplete
                                        v-model="selectedClass"
                                        :items="formClasses"                                        
                                        label="Select Class"
                                        chips                                        
                                        dense
                                        hide-details
                                        return-object
                                    >
                                        <template v-slot:item="data">
                                            <span v-if="data.item.teacher">{{ data.item.class_name }} - {{ data.item.teacher }}</span>
                                            <span v-else>{{ data.item.class_name }} - Group {{ data.item.class_group }}</span>
                                        </template>
                                        
                                        <template v-slot:selection="data">
                                            <v-chip
                                                v-bind="data.attrs"
                                                :input-value="data.selected"
                                                close
                                                @click:close="clearClassId"                                                
                                            >
                                                <span v-if="data.item.teacher">{{ data.item.class_name }} - {{ data.item.teacher }}</span>
                                                <span v-else>{{ data.item.class_name }} - Group {{ data.item.class_group }}</span>
                                            </v-chip>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                                <v-col 
                                    cols="12"                                    
                                >
                                    <v-autocomplete
                                        v-model="selectedTerm"
                                        :items="terms"                                        
                                        label="Select Term"
                                        item-text="title"
                                        chips
                                        deletable-chips
                                        dense
                                        hide-details
                                        return-object
                                    ></v-autocomplete>
                                </v-col>
                                <v-col
                                    cols="12"
                                >
                                   <date-picker-range
                                        label="Select Date Range"
                                        v-on:update-range="setDateRange"
                                        :selectedDate="dateRange"
                                   ></date-picker-range>
                                </v-col>
                            </v-row>  
                        </v-card-text>

                        <v-card-text
                            v-if="menu.failedLogins"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                   <date-picker-range
                                        label="Select Date Range"
                                        v-on:update-range="setDateRange"
                                        :selectedDate="dateRange"
                                   ></date-picker-range>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions 
                            class="py-0"                            
                        >
                            <v-btn
                                color="primary"
                                dark
                                @click="displayReport"
                                :block="blockButton"
                                class="ml-auto"
                            >
                                Display Report
                            </v-btn>
                        </v-card-actions>

                         <v-overlay
                            :absolute="absolute"
                            :value="overlayLoading"                    
                            color="grey lighten-5"
                            opacity="0.9"
                            class="pa-5"
                        > 
                            <v-progress-circular
                                indeterminate 
                                size="64"                            
                                color="primary"                                
                            ></v-progress-circular>                   
                        </v-overlay>

                        <v-snackbar
                            v-model="snackbar.show"
                            :color="snackbar.color"
                            timeout="2000"
                        >
                            {{ snackbar.text}}
                            <template
                                v-slot:action="{ attrs }"
                            >
                                <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
                            </template>
                        </v-snackbar>
                    </v-card>

                </v-overlay>                             
            </v-card>

            <v-container
                fluid
                class="pa-0"
                v-if="!overlay"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        <v-btn
                            color="primary"
                            small
                            dark
                            @click="closeReport"
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                    </v-row>
                </v-sheet>
            </v-container>
           
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import DatePickerRange from './DatePickerRange'
export default {
    components: {
        DatePickerRange,
    },

    mounted: function () {
        this.initialize();
    },
    
    data: () => ({
        reports: [
            // {id: 1, title: "Student by Class/Subject", value: 'studentSubject', menu: true},
            {id: 2, title: "E-Report Student Access Logs", value: 'reportCardAccess', menu: true},
            {id: 3, title: "Logins - Students Failed Logins", value: 'failedLogins', menu: true},
            {id: 4, title: "Logins - Students Never Logged In", value: 'studentsNoLogins', menu: false},
        ],
        dialog: false,
        src: '',
        absolute: true,
        overlay: false,
        overlayLoading: false,
        cardTitle: '',        
        formLevels: [],
        formLevelsUpperSchool: [],
        selectedFormLevel: 0,
        selectedSubjectId: null,
        subjects: [],
        selectedReport: null,
        menu: {},        
        formClassesRecords: [],
        formClasses: [],
        selectedClass: 0,
        terms: [],
        selectedTerm: {},
        dateRange: [],
        snackbar: {
            show: false,
            color: null,
            text: null,
        },
        selectedStudentId: null,
        maxWidth: 380,
        blockButton: false,
    }),

    computed: {
        ...mapGetters({
            //
        })
    },

    watch: {
        dateRange: {
            handler (val) {
                console.log(val);
            }
        }
    },

    methods: {
        ...mapActions({
            getFormLevels: 'admin/getFormLevels',
            getSubjects: 'admin/getSubjects',
            getFormClasses: 'admin/getFormClasses',
            getReportsPosted: 'admin/getReportsPosted',
        }),

        ...mapMutations({
            //
        }),

        async initialize () {
            this.overlayLoading = true;
            try {
                // const formLevelsPromise = this.getFormLevels();
                // const subjectsPromise = this.getSubjects();
                const formClassesPromise = this.getFormClasses();
                const reportsPostedPromise = this.getReportsPosted();

                // const { data: dataFormLevels } = await formLevelsPromise;
                // dataFormLevels.forEach(value => {
                //     if(value.form_level > 3)
                //     this.formLevelsUpperSchool.push(value.form_level);
                //     this.formLevels.push(value.form_level);
                // })

                // const { data: dataSubjects } = await subjectsPromise;
                // this.subjects = dataSubjects;

                const { data: dataFormClasses } = await formClassesPromise;
                // this.formClassesRecords = dataFormClasses;
                dataFormClasses.forEach(record => {
                    if(record.class_level)
                    this.formClasses.push(record);
                })
                
                const { data: dataReportsPosted } = await reportsPostedPromise;
                dataReportsPosted.forEach(record => {
                    this.terms.push({
                        title: record.year + ' Term ' + record.term,
                        year: record.year,
                        term: record.term 
                    })
                })

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }

            this.overlayLoading = false;
        },

        displayReportMenu (report) {
            this.overlay = true;
            this.maxWidth = 380;
            this.blockButton = true;
           
            this.selectedReport = report;
            this.selectedTerm = {
                year: 0,
                term: 0
            }

            this.reports.forEach(item => {                
                if(item.value == report.value){
                    if(item.menu){
                        this.cardTitle = item.title;
                        this.menu[item.value] = true;
                    }
                    else{
                        this.displayReport();
                    }                    
                    
                }
                
                else{
                    this.menu[item.value] = false;
                }                 
                
            })

            this.dialog = true;           
        },

        clearMenu () {
            Object.keys(this.menu).forEach(key => {
                this.menu[key] = false;
            })
        },

        displayReport () {
            const baseUrl = process.env.VUE_APP_API_URI  + '/api/';
            // console.log(this.selectedTerm);
            switch (this.selectedReport.value) {
                case "reportCardAccess":
                    this.src = this.addParamsToUrl(baseUrl + "report-card-access-logs", {
                        year: this.selectedTerm.year,
                        term: this.selectedTerm.term,
                        className: this.selectedClass.class_name,
                        classGroup: this.selectedClass.class_group,
                        startDate: this.dateRange[0],
                        endDate: this.dateRange[1]
                    });
                    console.log(this.src)
                    // if(this.dateRange.length == 0) {
                    //     this.src = baseUrl + "report-card-access-logs?" +
                    //     `year=${this.selectedTerm.year}&` + 
                    //     `term=${this.selectedTerm.term}&` + 
                    //     `className=${this.selectedClass.class_name}&` + 
                    //     `classGroup=${this.selectedClass.class_group}&`;
                    // } else {
                    //     this.src = baseUrl + "report-card-access-logs?" +
                    //     `year=${this.selectedTerm.year}&` + 
                    //     `term=${this.selectedTerm.term}&` + 
                    //     `className=${this.selectedClass.class_name}&` + 
                    //     `classGroup=${this.selectedClass.class_group}&` + 
                    //     `startDate=${this.dateRange[0]}&` +
                    //     `endDate=${this.dateRange[1]}`;
                    // }
                    break;
                case "failedLogins":
                    this.src = baseUrl + "report-failed-logins/" +
                    this.dateRange.join("/");
                    break;
                case "studentsNoLogins":
                    this.src = baseUrl + "no-logins-student";
                    break;
            }
            this.overlay = false;
        },

        addParamsToUrl(url, params) {
            const urlObj = new URL(url);
            Object.keys(params).forEach(key => {
                if(params[key] !== undefined && params[key] !== null && params[key] !== '' && params[key] !== 0) {
                    urlObj.searchParams.append(key, params[key]);
                }
            });
            return urlObj.toString();
        },

        closeReportMenu () {
            this.dialog = false;
            this.src = '';
            this.selectedReport = null;
            this.selectedSubjectId = null;
            this.selectedFormLevel = null;
            this.clearMenu();
        },

        closeReport () {
            if(this.selectedReport.menu){
                this.overlay = true; 
                this.src = '';
            }
            else this.closeReportMenu();
            // console.log(this.dateRange);
        },

        setDateRange (dates) {            
            this.dateRange = dates;
        },

        setFormClasses () {
            if(this.selectedFormLevel){
                this.formClasses = [];
                this.formClassesRecords.filter(record => {
                    if(record.form_level == this.selectedFormLevel)
                    this.formClasses.push(record.id);
                })
                // console.log(test);
            }
            else{
                console.log('no selected form level');
                this.selectedFormLevel = 0;
                console.log(this.formClassesRecords);
                // this.formClasses = this.formClassesRecords.forEach(record => {
                //     this.formClasses.push(record.id);
                // })                                
            }
        },

        clearFormLevel () {
            console.log("form level cleared");
            this.selectedFormLevel = 0;
            // console.log(this.formClasses);
            let formClasses = [];
            this.formClassesRecords.forEach(record => {
                if(record.form_level)
                formClasses.push(record.id);
            })
            // console.log(formClasses);
            this.formClasses = formClasses;
        },

        clearClassId () {
            this.selectedClass = {};
        },


    }
}
</script>

