<template>
    <v-container class="pt-0 pb-0">
        <v-row justify="center">

            <v-col class="flex-grow-0">
                <v-card 
                    tile
                    width="300"
                    class="pb-0 mb-0"
                    flat
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0 mb-0"
                        two-line
                    >
                        <v-list-item-group color="primary">
                            <v-list-item class="pl-0">                                
                                <v-list-item-content
                                    class="pa-0 mb-3 mt-3"
                                >
                                    <v-list-item-title>
                                        <span class="pr-1">Name:</span><span class="font-weight-bold">{{ studentName }}</span>                                   
                                    </v-list-item-title>                                    
                                </v-list-item-content>                                
                            </v-list-item>
                            <v-list-item class="pl-0">                                
                                <v-list-item-content
                                    class="pa-0 mb-4"
                                >
                                    <v-list-item-title>
                                        <span class="pr-1">Subject:</span><span class="font-weight-bold">{{ lessonSelected.subjectTitle }}</span>                                    
                                    </v-list-item-title>                                    
                                </v-list-item-content> 
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>                    
                    <div class="d-flex mt-2 align-end">
                       
                        <v-text-field
                            label="Year"
                            class="mr-1 caption "
                            dense
                            outlined
                            v-model="academicYear"
                            hide-details
                            readonly
                        ></v-text-field>
                        <v-text-field
                            label="Term"
                            v-model="termName"
                            class="mr-1 caption  small-input term-name"
                            dense
                            outlined
                            hide-details
                            readonly
                        ></v-text-field>
                   </div> 
                   <div class="d-flex mt-2 align-end"> 
                        <v-text-field
                            label="Class"
                            class="mr-1 caption  small-input"
                            dense
                            outlined                        
                            v-model="lessonSelected.className"
                            hide-details
                            readonly
                        ></v-text-field>
                    </div>  
                </v-card>                
            </v-col>
            
            <v-col
                class="d-flex flex-column justify-end mb-0 flex-grow-0"
            >
                <div class="d-flex">
                    <v-card flat class="text-caption">Monthly Test Max</v-card>
                    <v-card flat width="70">
                        <v-text-field
                            v-model="monthlyTestMax"
                            class="text-center mb-3"
                            color="primary"
                            type="number"
                            outlined
                            dense
                            hide-details
                            height="30"
                            @blur="updateMonthlyTestMax"
                        >
                        </v-text-field>
                    </v-card> 
                </div>
                <v-btn  
                    color="primary"
                    outlined
                    dark
                    x-small
                    @click="dialog = true"
                    class="mb-4 flex-grow-0"
                    block
                    height="30"
                >
                    Import Weekly Tests
                </v-btn>

                <v-btn  
                    color="primary"
                    dark
                    x-small
                    @click="nextSubjectClass"
                    block
                    class="flex-grow-0"
                    height="30"
                >
                    Next Subject
                </v-btn>
               
            </v-col>
            
            <v-col class="d-flex flex-grow-0 align-end">
                <v-card
                    height="132"
                    width="155"                    
                    tile 
                    class="pb-0"
                    flat 
                    outlined                                   
                >
                    <v-list 
                        dense
                        flat
                        class="pa-0 fill-height"                                               
                    >
                        <v-list-item-group 
                            color="primary"
                            class="d-flex flex-column fill-height"
                        >
                            <v-list-item
                                v-for="(item, i) in classSummary"
                                :key="i"                            
                            >
                                <v-list-item-content>
                                    <v-list-item-title 
                                        class="caption"
                                    >
                                        {{ item.text }}
                                    </v-list-item-title>
                                </v-list-item-content>                               
                                <v-list-item-action
                                    class="ma-0"
                                >
                                    <v-chip 
                                        label
                                        outlined
                                        color="blue"
                                        small                                   
                                    >
                                    {{ item.value }}
                                    </v-chip>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>    
                    </v-list>                    
                </v-card>
            </v-col>                
            
            <v-col v-if="image" class="flex-grow-0">
                <v-card
                    width="100"                    
                    tile                     
                    class="pb-0"                                    
                >
                    <v-img                    
                        :src="imageSrc"
                        width="100"                        
                    ></v-img>
                </v-card>
            </v-col>

        </v-row>

        <v-dialog
            v-model="dialog"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Confirm Weekly Test Import
                </v-card-title>

                <v-card-text class="text-h6 my-6 font-weight-regular">
                    Are you sure you want to import weekly tests for this subject?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="dialog = false" class="mr-4">Cancel</v-btn>
                    <v-btn depressed color="primary" @click="importWeeklyTestsConfirm">Yes Import</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
    watch: {
        studentSelected: {
            handler: function (val) {
                this.studentName = val.studentName;
                if(val.picture === null){
                    this.imageSrc = null;
                }
                else{
                    this.imageSrc = "/img/students/" + val.picture;                    
                }
            },
            deep: true,
        },
        
        studentsRegistered: {
            handler: function (val) {
                this.classSummary[0].value = val; 
                this.classSummary[1].value = val;
            }
        },
        studentsMarksEntered: {
            handler: function (val) {  
                this.setMarkSheetMarksEntered(val);
                this.classSummary[2].value = this.markSheetMarksEntered;
            }
        },


        table2Records: {
            handler: function (val) {
                this.monthlyTestMax = val[0]?.course_mark_max;
            }
        },

        markSheetMarksEntered: {
            handler: function (val) {
                this.classSummary[2].value = val;
            }
        }
    },
    computed: {
        ...mapGetters({
            lessonSelected: 'termReports/getLessonSelected',
            academicYear: 'termReports/getAcademicYear',
            term: 'termReports/getCurrentTerm',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',
            studentName: 'termReports/getMarkSheetStudentSelected',
            table2Records: 'termReports/getTable2Records',
            employeeId: 'auth/getEmployeeId',
            markSheetMarksEntered: 'termReports/getMarkSheetMarksEntered',
            admin: 'auth/getAdmin',
                      
        }),        
        termName(){
            let termName = '';
            if(this.term == 1) termName =  '1';
            if(this.term == 2) termName = '2';
            if(this.term == 3) termName = '3';
            return termName
        }
    },
    data(){
        return {                     
            nameLabel: "Name",
            imageSrc: '',           
            classSummary: [
                {text: '# In Class:', value: ''},
                {text: '# Registered:', value: ''},
                {text: '# Entered:', value: ''},
            ],
            image: false,
            monthlyTestMax: 100,
            dialog: false,
        }
    },
    methods: {
        ...mapMutations({
            setOverlay: 'termReports/setMarkSheetOverlay',
            expand: 'termReports/setExpandLessonOptions',            
            setTable2Records: 'termReports/setTable2Records',
            setStudentName: 'termReports/setMarkSheetStudentSelected',
            setOverlayLessons: 'termReports/setOverlayLessons',
            setWeeklyTestRecord: 'weeklyTests/setWeeklyTestRecord',
            setMarkSheetMarksEntered: 'termReports/setMarkSheetMarksEntered',
        }),
        
        nextSubjectClass(){
            this.setTable2Records([]);
            this.$emit('admin-next-subject');
            this.setOverlayLessons(true);
            this.setOverlay(true);
            this.setStudentName('');            
        },

        updateMonthlyTestMax(){
            this.setTable2Records(this.table2Records.map(item => {
                item.course_mark_max = this.monthlyTestMax;
                return item;
            }));
            
        },

        importWeeklyTestsConfirm(){
            this.setWeeklyTestRecord({
                form_class_id: this.lessonSelected.classId,
                subject_id: this.lessonSelected.subjectCode,
                employee_id: this.employeeId,
            })
            this.$emit('import');
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
    .centered-input >>> input{
        text-align: center;
    }
    /* .small-input >>> input{
        width: 35px;;
    } */
    .v-list-item{
        min-height: 0;
    } 
    .term-name >>> input{
        width: 50px !important
    } 

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        text-align: center;
    } 
</style>