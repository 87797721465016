<template>
    <v-dialog
        v-model="dialog"
        max-width="800px"
        persistent
    >
        <template
            v-slot:activator="{ on, attrs }"
        >
            <v-btn
                depressed 
                outlined 
                color="primary" 
                small
                v-bind="attrs"
                v-on="on"
                :class="cssClass"
            >
                {{ btnText }}
            </v-btn>
        </template>
        
        <v-card v-if="dialog">
            <v-card-title class="d-flex white--text  py-2 px-0 primary">
                <v-spacer></v-spacer>
                <div class="font-weight-light text-uppercase">
                    {{ markSheetTitle }}
                </div>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    icon
                    color="white"
                    @click="close"  
                    class="mr-2"
                >
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col class="flex-grow-0">
                        <v-card
                            flat
                            width="300"
                            class="my-4"
                        >
                            <div class="d-flex align-end">
                                <v-text-field
                                    label="Year"
                                    v-model="academicYear"
                                    class="mr-1 caption "
                                    dense
                                    outlined
                                    hide-details="auto"
                                    readonly
                                ></v-text-field>

                                <v-text-field
                                    label="Term"
                                    v-model="term"
                                    class="mr-1 caption"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    readonly
                                ></v-text-field>
                            </div>
                            <div class="d-flex mt-2 align-end">
                                <v-text-field
                                    label="Class"
                                    v-model="getWeeklyTestSelected.className"
                                    class="mr-1 caption"
                                    dense
                                    outlined
                                    hide-details="auto"
                                    readonly
                                ></v-text-field>
                            </div>
                        </v-card>
                    </v-col>

                    <v-col>
                        <v-card
                            flat
                            class="d-flex my-4"
                            width="180"
                        >
                            <div class="text-caption mr-3" style="width:100px">Weekly Test Max</div>
                            <div>
                                <v-text-field
                                    v-model="weeklyTestMax"
                                    class="text-center mb-3"
                                    color="primary"
                                    type="number"
                                    outlined
                                    dense
                                    hide-details="auto"
                                ></v-text-field>
                            </div>
                            
                        </v-card>
                    </v-col>
                </v-row>

                <v-data-table
                    :items="weeklyTestRecords"
                    :loading="loading"
                    :headers="headers"
                    fixed-header
                    disable-pagination
                    hide-default-footer
                    height="50vh"
                >
                    <template v-slot:item="{ item }">
                        <Row 
                            :record="item"
                            :max-mark="weeklyTestMaxUpdated"
                        ></Row>
                    </template>
                </v-data-table>
            </v-card-text>

            <v-card-actions class="pr-6">
                <v-spacer></v-spacer>
                <v-btn depressed @click="close">Close Mark Sheet</v-btn>
            </v-card-actions>

            <v-overlay
                :value="overlay"
                absolute                  
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>                   
            </v-overlay>
           
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Row from './WeeklyTestMarkSheetRecord.vue';
export default {
    props: {
        btnText: String,
        subject: Object,
        weekEndDate: String,
        cssClass: String,
    },

    components: {
        Row
    },

    data: function () {
        return {
            dialog: false,
            loading: false,
            headers: [
                {text: '#', value: 'count', width: '60', sortable: false},
                {text: 'Name', value: 'name', width: '200',  sortable: false},
                {text: 'Max Mark', value: 'max_mark', align: 'center', width: '90', sortable: false,},
                {text: 'Mark', value: 'mark', align: 'center', width: '90', sortable: false,},
                {text: '%', value: 'percentage', align: 'center', width: '90', sortable: false,},
                {text: '', value: 'action', align: 'center', width: '60', sortable: false,},
            ],
            weeklyTestRecords: [],
            weeklyTestMax: 50,
            overlay: false,
        }
    },

    computed: {
        ...mapGetters({
            employeeId: 'auth/getEmployeeId',
            academicYear: 'termReports/getAcademicYear',
            term: 'termReports/getCurrentTerm',
            getWeeklyTestSelected: 'weeklyTests/getWeeklyTestSelected',
        }),

        markSheetTitle () {
            return `${this.getWeeklyTestSelected.weekEndDate}  ${this.subject.title} Weekly Test Mark Sheet `
        },

        weeklyTestMaxUpdated () {
            return Number(this.weeklyTestMax)
        }
    },

    watch: {
        dialog: {
            handler (val) {
                if(val) this.initialize();
            }
        },

    },

    methods: {
        close () {
            this.dialog = false;
            this.weeklyTestRecords = [];
            this.$emit('close');
        },

        ...mapMutations({
            setWeeklyTestSubject: 'weeklyTests/setWeeklyTestSubject',
        }),

        ...mapActions({
            getWeeklyTest: 'weeklyTests/getWeeklyTest',
        }),

        async initialize () {
            this.loading = true;
            this.overlay = true;
            this.setWeeklyTestSubject({
                subjectId: this.subject.subject_id,
                employeeId: this.employeeId,
                weekEndDate: this.weekEndDate,
                maxMark: this.subject.max_mark,
            })
            try {
                const { data } = await this.getWeeklyTest();
                this.weeklyTestRecords = data;
                this.weeklyTestMax = data[0].max_mark;
                this.$emit('update-subjects');
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
            }
            this.loading = false;
            this.overlay = false;
        },

        setWeeklyTestMax () {
            console.log('weekly test max', this.weeklyTestMax)
        }
    }


}
</script>

<style scoped>
    .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(60 60 60);
    }

    .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(odd){
        background-color: rgb(250, 250, 250);
    }

     /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        text-align: center;
    } 
</style>