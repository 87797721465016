<template>
    <v-card
        width="800"
        
        class="mx-auto pa-4 mt-4"        
        elevation="2"
        outlined
    >
        <v-card-title
            class="primary--text secondary"
        >
            Subjects
        </v-card-title>

        <EditViewSubjects 
            v-bind:subjects="subjects"
            :loading="loading"
            v-on:update-subjects="initialize"
        ></EditViewSubjects>   
         
       <v-overlay
            absolute
            :value="overlay"
            opacity="0.7"
        >
            <v-progress-circular 
                indeterminate 
                size="64"                    
                color="white"
            ></v-progress-circular>   
        </v-overlay>

         
    </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import EditViewSubjects from './AdminSubjectsEditView';
// import SubjectsStudentAssignment from './AdminSubjectsStudentAssignment';
// import BatchStudentSubjectAssignment from './AdminSubjectsStudentBatch';
export default {
    created: function () {
        this.initialize();
    },

    components: {
        EditViewSubjects,
        // SubjectsStudentAssignment,
        // BatchStudentSubjectAssignment,
    },
    
    data: () => ({
        
        subjects: [],
        teachers: [],
        formLevels: [],
        formClasses: [],
        students: [],
        tabOptions: [
            'Edit/View Subjects'
        ],
        tab: null,
        overlay: false,
        loading: false,
    }),

    methods: {
        ...mapActions({            
            getSubjects: 'admin/getSubjects',            
            getEmployees: 'admin/getEmployees',
            getFormLevels: 'admin/getFormLevels',
            getFormClasses: 'admin/getFormClasses',
            getStudents: 'admin/getStudents',           
        }),

        async initialize () {
            this.overlay = true;
            console.log('initialize..')

            try {
                const { data } = await this.getSubjects();
                this.subjects = data
                
            } catch (error) {
                if(error.response){
                    console.log(error.response)
                    return
                }
                console.log(error)
            }
            this.loading = false
            this.overlay = false;
               
        },

        showOverlay (display = false) {
            this.overlay = display;            
        }

    }
}
</script>