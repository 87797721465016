<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Select Data Columns
        </v-card-title>

        <v-card-text>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="dataFieldsFilter"
                item-key="value"
                show-select
                hide-default-footer
                :items-per-page="itemsPerPage"
                height="40vh"
                fixed-header
                :search="search"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                  clearable
                ></v-text-field>
              </template>
            </v-data-table>

            <v-autocomplete
                v-model="selected"
                :items="dataFieldsFilter"
                deletable-chips
                small-chips
                multiple
                readonly
                label="Selected Columns"
                class="mt-8"
            ></v-autocomplete>

        </v-card-text>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            @click="close"
            class="mr-4"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            @click="addColumns"
          >
            Download Spreadsheet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
  export default {
    created () {
      this.initialize();
    },

    props: {
      dataFields: Array,
      dialog: Boolean,
    },

    data () {
      return {
        selected: [],
        headers: [
            {
                text: 'Student Data Columns',
                value: 'text'
            }
        ],
        itemsPerPage: -1,
        dataFieldsFilter: [],
        search: '',
      }
    },

    computed: {
        ...mapGetters({
            studentDataHeaders: 'admin/getStudentDataHeaders',
            userAdmin: 'admin/getUserAdmin',
        })

    },

    methods: {
        ...mapMutations({
            setStudentDataFields: 'admin/setStudentDataFields',
            setStudentDataHeaders: 'admin/setStudentDataHeaders',
        }),

        initialize () {
          this.selected = this.dataFields.filter(value => value.default);
          //this.dataFieldsFilter = [...this.dataFields];
          this.dataFieldsFilter = this.dataFields.filter(obj => obj.value !== 'actions')
          if(this.userAdmin === 'PTA') {
            this.dataFieldsFilter = this.dataFields.filter(field =>!field.admin);
            this.selected = this.dataFields.filter(value => (value.default && !value.admin))
          }
          
        },

        close () {
            this.$emit('close');
        },

        addColumns () {
          this.setStudentDataFields(this.selected);
          this.setStudentDataHeaders(this.selected);
          this.$emit('addColumns');
        }
    }
  }
</script>