<template>
    <tr @click="recordSelected">
        <td style="padding: 0 6px">{{ markRecord.count }}</td>
        
        <td class="pt-1" style="padding: 0 6px">
            {{ markRecord.name }}
        </td> 

        <td  style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.course_mark_max"
                outlined
                dense
                hide-details
                type="number" 
                disabled
                filled               
            >                
            </v-text-field>
        </td>

        <td  style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.course_mark"
                outlined
                dense
                min=0
                :max="markRecord.course_mark_max"
                hide-details
                type="number"                
                @blur="recordChanged"    
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"             
            >                
            </v-text-field>
        </td>

        <td  style="padding: 0 6px">
            <v-text-field
                v-model="markRecord.exam_mark"
                outlined
                dense
                min=0
                :max="maxTermTest"
                hide-details
                type="number"
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
            ></v-text-field>
        </td>

        <td style="padding: 0 6px">
            <v-tooltip 
                bottom
                v-if="saveStates.saved"
            >
                <template  v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="green"                        
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                v-else-if="saveStates.error"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                        
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                v-else-if="saveStates.saving"
                indeterminate
                color="primary"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>

    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props:{
        studentRecord: Object,
        formLevel: Number,        
    },
    created () {
        this.initialize();
    },  
    watch: {        
        studentRecord: {
            deep: true,
            handler () {
                this.initialize();
            }
        }       
    },       
    data() {
        return {
            commentsConduct: [],
            commentsApplication: [],
            commentsPreparedness: [],            
            comments1: [],
            comments2: [],            
            markRecord: {},
            saved: false,
            saving: false,
            error: false,
            saveStates: {
                saved: false,
                saving: false,
                error: false
            },
            maxMontlyTest: 300,
            maxTermTest: 100,
        }
    },
    computed: {
        ...mapGetters({
            codedComments: 'termReports/getCodedComments',
            employeeId: 'auth/getEmployeeId',
            marksEntered: 'termReports/getSubjectStudentsMarksEntered',
            lessonSelected: 'termReports/getLessonSelected',
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            commentSheet: 'termReports/getCommentSheetEnterMarks', 
            markSheetMarksEntered: 'termReports/getMarkSheetMarksEntered',
        }),
       
    },
    methods : {
        ...mapMutations({
            setUpdatedRecord: 'termReports/setUpdatedTable2Record',
            setStudentSelected: 'termReports/setMarkSheetStudentSelected',
            setMarkSheetEntered: 'termReports/setSubjectStudentsMarksEntered',
            setCommentSheet: 'termReports/setCommentSheetEnterMarks',
            setSelectedTable2RecordIndex: 'termReports/setSelectedTable2RecordIndex',
            setMarkSheetMarksEntered: 'termReports/setMarkSheetMarksEntered',
            setTable2Records: 'termReports/setTable2Records',
        }),

        ...mapActions({
            save: 'termReports/saveTable2Record',
        }),
        
        initialize(){
            this.markRecord = {...this.studentRecord};
            this.setStudentSelected(null);
            this.savedDataCheck();
                       
        },

        async recordChanged(){
            if(this.recordBlankCheck()) return;

            this.setSaveStatus();
                
            this.setUpdatedRecord(
                {
                    "student_id" : this.markRecord.student_id,
                    "year" : this.markRecord.year,
                    "term" : this.markRecord.term,
                    "subject_id" : this.markRecord.subject_id,
                    "exam_mark" : this.markRecord.exam_mark,
                    "course_mark" : this.markRecord.course_mark,
                    "app1" : this.markRecord.app1,
                    "con1" : this.markRecord.con1,                   
                    "employee_id" : this.employeeId, 
                    "course_mark_max": this.markRecord.course_mark_max,                  
                }
            );
                
            try {
                await this.save();
                
                this.updateLocalTable2Records();

                this.updateMarkSheetMarksEntered();

                this.setSaveStatus('saved')
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
                this.setSaveStatus('error')
            }
        },

        updateLocalTable2Records () {
            const index = this.table2Records.findIndex(record => record.student_id === this.studentRecord.student_id);
            if(index !== -1){
                const newTable2Records = [...this.table2Records];
                newTable2Records[index] = {...this.markRecord};
                this.setTable2Records(newTable2Records);
            }
        },

        updateMarkSheetMarksEntered(){
            if(!this.studentRecord.exam_mark && !this.studentRecord.course_mark){
                this.setMarkSheetMarksEntered(this.markSheetMarksEntered + 1);
            }
        },

        recordBlankCheck() {
            return !this.markRecord.course_mark && !this.markRecord.exam_mark;
        },

        savedDataCheck(){
            if(!this.recordBlankCheck()) this.saved = true;
        },

        recordSelected(){
            // let records = this.table2Records;
            // let entered = 0;
            // records.forEach(record => {
            //     if(record.course_mark || record.exam_mark || record.coded_comment || record.coded_comment_1){
            //         entered++;
            //     }
            // })
            // console.log(entered);
            this.setStudentSelected(this.markRecord.name);
        },        

        setSaveStatus (saveState = 'saving') {
            Object.keys(this.saveStates).forEach(key => {
                if(key === saveState){
                    this.saveStates[key] = true;
                    return;
                } 
                this.saveStates[key] = false;
            })
        }
    }
}
</script>

<style scoped> 
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 6px;
    }

    /* Chrome, Safari, Edge, Opera */
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align:center
    }

    /* Firefox */
    ::v-deep input[type=number] {
        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        text-align: center;
    }
</style>