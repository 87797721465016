<template>
    <v-container class="mt-6 pb-0 d-flex justify-center">
        <v-card
            flat
            width="900"
            class="px-6"
        >
            <v-data-table
                :items="table2Records"
                :loading="loading"
                :headers="headers"
                :height="height"            
                fixed-header           
                class="enter-marks-table"
                disable-pagination
                hide-default-footer                                       
            >
                <template v-slot:item="props">
                    <Record
                        v-bind:studentRecord="props.item"
                        v-bind:formLevel="lessonSelected.formLevel"
                    />
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    
    data: () =>({
        loading: false,
        height: "55vh",
        pagination: false,
        footer: false,                
        headers: [
            {text: '', value: 'count', align: 'left', width: '45', sortable: false},
            {text: 'Name', value: 'name',  sortable: false},
            {text: 'Monthly Test Max', value: 'course_mark_max', align: 'left', width: '80', sortable: false,},
            {text: 'Monthly Test', value: 'course', align: 'left', width: '80', sortable: false,},            
            {text: 'Term Test', value: 'exam', align: 'left', width: '80', sortable: false,},    
            {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'}, 
        ],
    }),

    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
        }),

    },

    methods: { 
        //
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
    }
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        padding: 0 6px;
    }

    ::v-deep .v-data-table-header th{
        vertical-align: top;
    }
</style>