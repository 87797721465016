<template>
    <v-card
        class="elevation-4 mx-auto"
        width="650"
    >
        
        <v-row class="pa-4">                    
            <v-col cols="9">
                <v-list>                            
                    <v-list-item-group v-model="menu">
                        <v-list-item
                            v-for="(item, i) in items"
                            :key="i"
                            @click="selectOption(item)"
                        >
                            <v-list-item-icon>
                                <v-icon 
                                    color="primary"
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title 
                                    :class="fontClass"
                                >
                                    {{ item.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list> 
            </v-col>
            <v-col cols="3">
                <v-img
                    src="../assets/logo.png"
                    max-width="120"
                    contain
                    class="d-flex"
                ></v-img> 
            </v-col>
        </v-row>          
    </v-card>    
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    name: 'MainMenu',
    data: () => ({
        items: [           
            {
                icon: 'mdi-keyboard',
                text: 'Enter Marks - Weekly Test',
                value: 'weekly-test',
            },
            {
                icon: 'mdi-keyboard',
                text: 'Enter Marks - End of Term',
                value: 'term-test',
            },
            {
                icon: 'mdi-pencil',
                text: 'Edit/View Term Reports',
                value: 'term-reports',
            },
            // {
            //     icon: 'mdi-notebook-edit',
            //     text: 'Mark Book',
            //     value: 'mark-book'
            // }
        ],
        menu: '',
        step: 1,  
    }),
    computed: {
        ...mapGetters({
            user: 'auth/getEmployeeSignedIn',
            getMarkSheetLoading: 'termReports/getMarkSheetLoading',
            getEditViewTermReportsOverlay: 'termReports/getEditViewTermReportsOverlay',            
            id: 'auth/getEmployeeId',
        }),
        titleClass(){
            return 'subtitle-1 font-weight-light font-italic primary--text ';
        },
        cardTitleClass(){
            return 'py-2 pl-8 caption primary--text font-weight-bold text-uppercase secondary';
        },
        fontClass(){
            return 'font-weight-normal text-uppercase primary--text ';
        }
    },
    
    methods: {
        ...mapMutations({
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',
            markSheetOverlay: 'termReports/setMarkSheetOverlay',            
            markSheetLoading: 'termReports/setMarkSheetLoading',
            editViewTermReportsOverlay: 'termReports/setEditViewTermReportsOverlay',
            editViewTermReportsLoading: 'termReports/setEditViewTermReportsLoading',
            formClasses: 'termReports/setFormClasses',
            setFormClassesList: 'termReports/setFormClassesList',
            setFormTeacherClass: 'termReports/setFormTeacherClass',
            setMainMenuButton: 'termReports/setMainMenuButton',
            setMarkBook: 'markBook/setMarkBook',
            setDeanFormClasses: 'termReports/setDeanFormClasses',
            setWeeklyTest: 'weeklyTests/setWeeklyTest',
            setTermTest: 'termReports/setTermTest'
        }),

        ...mapActions({
            getFormClasses: 'termReports/getFormClasses',
            getFormTeacherClass: 'termReports/getAssignedFormTeacherClass',
            getCodedCommentsList: 'termReports/getCodedCommentsList',  
            getDeanFormClasses: 'termReports/getDeanFormClasses',
        }),

        async selectOption ({ value }) {
            this.setMainMenuButton(true); 
            // this.welcomeMessage(false);
            this.mainMenu(false);
            this.displayMenuComponent(value);

            if(value === 'term-reports'){
                this.editViewTermReportsOverlay(true);
                this.editViewTermReportsLoading(true);
                // await this.setFormClases(); 
                // await this.mapDeanFormLevels();
            }            
        },

        displayMenuComponent (component) {
            let menuComponents = {
                'weekly-test' : this.setWeeklyTest,
                'term-test' : this.setTermTest,
                'term-reports' : this.editViewTermReports,
                'mark-book' : this.setMarkBook
            }
            Object.keys(menuComponents).forEach(key => {
                menuComponents[key](false);
                if(key === component){
                    menuComponents[key](true)
                }
            })
        },
        
        async mapDeanFormLevels () {
            try {
                const { data } = await this.getDeanFormClasses();
                this.setDeanFormClasses(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
        },
        
        async setFormClases(){
            const { data } = await this.getFormTeacherClass(this.id);
            this.setFormTeacherClass(data);
            const response = await this.getFormClasses();
            //console.log(response);
            this.setFormClassesList(response.data);
            let formClasses = [];
            let form1Classes = [];
            let form2Classes = [];
            let form3Classes = [];
            let form4Classes = [];
            let form5Classes = [];
            let form6Classes = [];            
            response.data.forEach(record => {
                switch(record.form_level){
                    case 1:
                    form1Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 2:
                    form2Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 3:
                    form3Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 4:
                    form4Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 5:
                    form5Classes.push({
                        title: record.class_id
                    });
                    break;
                    case 6:
                    form6Classes.push({
                        title: record.class_id
                    });
                    break;
                }                
            });
            formClasses.push({
                title: 'Form 1',
                action: 'mdi-school',                
                items: form1Classes
            });
            formClasses.push({
                title: 'Form 2',
                action: 'mdi-school',                
                items: form2Classes
            });
            formClasses.push({
                title: 'Form 3',
                action: 'mdi-school',                
                items: form3Classes
            });
            formClasses.push({
                title: 'Form 4',
                action: 'mdi-school',                
                items: form4Classes
            });
            formClasses.push({
                title: 'Form 5',
                action: 'mdi-school',                
                items: form5Classes
            });
            formClasses.push({
                title: 'Form 6',
                action: 'mdi-school',                
                items: form6Classes
            });            
            this.formClasses(formClasses);
            //console.log('main menu getting coded comments...');
            await this.getCodedCommentsList();
        },
        setTerm(term){
            console.log(term);
            switch(term){
                case 202001:
                    this.step = 2;
                    break;
            }
        }
    }
}
</script>