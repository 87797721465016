<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            persistent
        >
            <v-card >
                <div class="d-flex justify-end pt-2 mx-2">
                    <v-btn
                        text
                        small
                        @click="cancelPromotion"
                    >
                        Close
                        <v-icon right>mdi-close</v-icon>
                    </v-btn>
                </div>
                <v-card-title class="text-h5 primary--text">
                    
                                    </v-card-title>
                <v-card-text class="text-h5 my-6">
                    <div v-if="defaultMessage && !successMessage && !errorMessage" class="font-weight-bold">{{ defaultMessage }}</div>
                    
                    <div v-else-if="successMessage && !errorMessage">
                        <div  class="checkmark-wrapper">
                            <span class="checkmark"></span>
                        </div>
                        <div class="text-h5 font-weight-bold text-center mt-4">{{ successMessage }}</div>
                    </div>

                    <div v-else-if="errorMessage" class="d-flex flex-column justify-center align-center">
                        <v-icon  color="red" x-large>mdi-alert-circle</v-icon>
                        <div class="text-h5 red--text font-weight-bold text-center mt-4">{{ errorMessage }}</div>
                    </div>

                </v-card-text>
                <v-card-actions class="d-flex mt-6 px-6">
                    <v-btn
                        depressed
                        @click="cancelPromotion"
                        class="flex-grow-1"
                    >
                        Close
                        <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="undoPromoteStudents"
                    >
                        <v-icon left>mdi-arrow-u-left-top</v-icon>
                        Undo Promote
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="promoteStudents"
                    >
                        Yes Promote
                    </v-btn>
                </v-card-actions>

                 <v-overlay
                    absolute
                    :value="overlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        initialDialog: Boolean,
    },

    computed: {
        ...mapGetters({
            menuOptions: 'admin/getMenuOptions',
        })
    },

    data: function () {
        return{
            dialog: this.initialDialog,
            overlay: false,
            defaultMessage: 'Are you sure you want to promote the students?',
            successMessage: null,
            errorMessage: null,
        }
    },

    methods: {
        ...mapActions({
            undoPromotion: 'admin/undoPromotion',
            postPromotion: 'admin/postPromotion',
        }),

        async promoteStudents(){
            this.overlay = true;
            this.successMessage = null;
            this.defaultMessage = null;
            try {
                await this.postPromotion();
                setTimeout(()=>{
                    this.successMessage = 'Promotion Successful.';
                }, 600)
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.errorMessage = "Promotion Failed."
            }

            
            this.overlay = false;
        },

        async undoPromoteStudents(){
            this.overlay = true;
            this.successMessage = null;
            this.defaultMessage = null;
            try {
                await this.undoPromotion();
                setTimeout(()=>{
                    this.successMessage = 'Undo Successful.';
                }, 600)
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                setTimeout(()=>{
                    this.errorMessage = "Undo Promotion Failed."
                }, 600)
                
            }
            this.overlay = false;
        },

        ...mapMutations({
            setMenuOptions: 'admin/setMenuOptions', 
        }),

        cancelPromotion(){
            this.dialog = false;
            // this.setMenuOptions({...this.menuOptions, promotion: false});
            this.setMenuStudents();
        },

        setMenuStudents () {
            let menuOptions = {...this.menuOptions};
             Object.keys(menuOptions).forEach(key => {
                menuOptions[key] = false;
            })
            this.setMenuOptions(menuOptions);
            this.$nextTick(() => {
                this.setMenuOptions({...this.menuOptions, students: true});
            })
        }
    }
}
</script>

<style scoped>
   
    .checkmark-wrapper {
        --check-size: 200px;
        --check-border-width: 5px;
        --checkmark-width: calc(var(--check-size) / 2);
        --checkmark-height: calc(var(--checkmark-width) / 2);
        --checkmark-left: calc(var(--checkmark-width) / 2);
        --checkmark-top: 50%;
        --checkmark-color: #19b8a2;
        width: var(--check-size);
        height: var(--check-size);
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

    .checkmark-wrapper::after {
        content: "";
        position: absolute;
        inset: 0;
        border: var(--check-border-width) solid #f8f8f8;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        z-index: 0;
    }

    .checkmark-wrapper::before {
        content: "";
        position: absolute;
        inset: 0;
        border: var(--check-border-width) solid transparent;
        border-left-color: var(--checkmark-color);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        z-index: 1;
        animation: circle linear forwards .75s;
    }

    .checkmark {
        height: var(--checkmark-height);
        width: var(--checkmark-width);
        position: absolute;
        opacity: 0;
        left: var(--checkmark-left);
        top: var(--checkmark-top);
        display: block;
        border-left: var(--check-border-width) solid var(--checkmark-color);
        border-bottom: var(--check-border-width) solid var(--checkmark-color);
        transform-origin: left top;
        transform: rotate(-45deg);
        animation: checkmark linear both 1s;
    }

    @keyframes circle {
        0% {
            border-color: transparent;
            border-left-color: var(--checkmark-color);
        }
        90% {
            transform: rotate(-360deg);
            border-color: transparent;
            border-left-color: var(--checkmark-color);
        }
        100% {
            transform: rotate(-360deg);
            border-color: var(--checkmark-color);
        }
    }

    @keyframes checkmark {
        0% {
            height: 0;
            width: 0;
            opacity: 0;
        }
        80% {
            height: 0;
            width: 0;
            opacity: 0;
        }
        90% {
            height: var(--checkmark-height);
            width: 0;
            opacity: 1;
        }
        100% {
            height: var(--checkmark-height);
            width: var(--checkmark-width);
            opacity: 1;
        }
    }
</style>