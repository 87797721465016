<template>
    <v-card
        class="pl-0 py-0"
        outlined
        flat
    >
        <v-sheet width="245">
             <v-data-table
                :headers="headers"
                :items="behaviourMatrix"
                dense
                height="350"
                disable-pagination
                hide-default-footer
                hide-default-header
             >
                <template v-slot:item="{ item }">
                    <tr>
                        <td style="width:130px; font-size:11px; height:34px">{{ item.title }}</td>
                        <td>
                            <v-card flat width="90">
                                <v-select
                                    v-model="studentRecord[item.model]" 
                                    outlined 
                                    dense
                                    hide-details
                                    :items="ratings"
                                    item-text="text"
                                    item-value="abbr"
                                    class="text-caption text-center"
                                    chips
                                    @change="updateRecord"
                                    :disabled="disabled"
                                    :filled="disabled"
                                >
                                    <template v-slot:selection="data">
                                        <v-chip
                                            v-bind="data.attrs"
                                            :input-value="data.selected"
                                            close
                                            @click="data.select"
                                            @click:close="remove(item.model)"
                                            small
                                        >
                                            {{ data.item.abbr }}
                                        </v-chip>
                                    </template>
                                    <template v-slot:item="{item}">
                                        {{ item.abbr }} - {{ item.text }}
                                    </template>
                                </v-select>
                            </v-card>
                        </td>
                    </tr>
                </template>
             </v-data-table>
        </v-sheet>
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    data: function () {
        return {
            ratings: [
                {text: 'Consistently', abbr: 'C'},
                {text: 'Sometimes', abbr: 'S'},
                {text: 'Rarely', abbr: 'R'},            
            ],
            behaviourMatrix: [
                {title: 'Communicates positively with others', model: 'communication', disabled: true},
                {title: 'Works co-operatively with others', model: 'cooperation', disabled: true},
                {title: 'Shows respect for others and property', model: 'respect', disabled: true},
                {title: 'Behaves responsibly', model: 'responsibility', disabled: true},
                {title: 'Displays a positive attitude', model: 'attitude', disabled: true},
                {title: 'Makes appropriate judgement', model: 'judgement', disabled: true},
                {title: 'Completes assignments', model: 'complete_assignment', disabled: true},
                {title: 'Participates in class', model: 'class_participation', disabled: true},
                {title: 'Shows self-confidence in  learning', model: 'self_confidence', disabled: true},
                {title: 'Punctual attendance to class', model: 'punctual', disabled: true},
            ],
            headers: [
                {text: '', value: 'title', align: 'left', width: '45', sortable: false},
                {text: '', value: 'actions', align: 'left', width: '45', sortable: false},
            ],
            disabled: true,
        }
    },

    watch: {
        studentRecord: {
            handler () {                
                this.setDisabledFields();
            }
        },

    },

     computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',
            formTeacherClass: 'termReports/getFormTeacherClass',
            admin: 'auth/getAdmin',
        })
    },

    methods: {
        ...mapActions({
            postTable1Record: 'termReports/postTable1Record',
        }),

        ...mapMutations({
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
        }),

        async updateRecord () {
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
        },

        remove (model) {
            this.studentRecord[model] = null;
            this.updateRecord();
        },

        setDisabledFields () {
            this.disabled = false;
            let found = this.formTeacherClass.some(element => {
                return element.form_class_id === this.studentRecord.form_class_id 
            });
            
            if(!found && !this.admin){
                console.log('Not the form Teacher');
                this.disabled = true;
                this.behaviourMatrix.forEach(element => {
                    element.disabled = true;
                })                    
            }
        },
    },
    
   
}
</script>

<style scoped>
    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-select__slot{
        height: 30px;
    }
    
    ::v-deep .v-chip.v-size--small{
        height: 18px;
        font-size: 11px;
    }
   
    ::v-deep .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner{
        margin-top: 3px;
    }
    
    ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
        padding: 0;
    }

    ::v-deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
        height: 34px;
    }
</style>