<template>
    <v-col class="pr-0 py-0 mb-0 ml-2">
        <v-row class="mr-0">
            <v-card
                flat
                :width="width"
                align="center"
                justify="center"
                color="grey lighten-5"
                dark
            >
                <div 
                    class="mr-3 text-subtitle-1 green--text"                                         
                > 
                    {{ studentName }}
                </div>                                 
                <div 
                    class="text-caption green--text"
                >
                    {{ className }}
                </div>
            </v-card>
        </v-row>

        <v-row class="mr-0">
            <v-card                                    
                :width="width"
                flat
                color="grey lighten-5"                
                dark                                    
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption blue--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mr-0 mt-3">
            <v-card
                :width="width"
                flat
            >
                <div>
                    <v-list class="py-0" dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                            class="pr-0 mb-2"
                        >
                            <v-list-item-content class="py-0">
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card                               
                                width="80"                                                                                                       
                                flat
                            >
                                <v-menu
                                    v-if="item.menu"
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :close-on-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="participationPercentage"
                                            readonly
                                            :disabled = "item.disabled"
                                            :filled = "item.disabled" 
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            type="number"
                                            hide-details
                                            height="30"
                                            class="text-caption pa-0"
                                        ></v-text-field>
                                    </template>
                                    <v-card>
                                        <v-card-title class="px-8">
                                            Participation
                                        </v-card-title>
                                        <v-card-text>
                                            <v-list>
                                                <template v-for="(participationAtrribute,index) in participationAttributes">
                                                    <v-list-item
                                                        :key="index"
                                                    >
                                                        <v-list-item-content>
                                                            <v-card flat class="text-caption" width="150">
                                                                {{ participationAtrribute.title }}
                                                            </v-card>
                                                        </v-list-item-content>
                                                        <v-card
                                                            flat
                                                            width="70"
                                                        >
                                                            <v-text-field
                                                                v-model="studentRecord[participationAtrribute.model]"
                                                                outlined
                                                                hide-details
                                                                dense
                                                                class="mb-3"
                                                                type="number"
                                                                min=0
                                                                :max="participationAtrribute.max"
                                                                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
                                                                @blur="updateParticipationPercentage"
                                                            ></v-text-field>
                                                        </v-card>
                                                    </v-list-item>
                                                </template>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-card flat class="text-caption" width="150">
                                                            Total
                                                        </v-card>
                                                    </v-list-item-content>    
                                                    <v-card flat width="70">
                                                        <v-text-field
                                                            v-model="participationTotal"
                                                            outlined
                                                            hide-details
                                                            dense
                                                            class="mb-3"
                                                            disabled
                                                            filled
                                                        ></v-text-field>
                                                    </v-card>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-card flat class="text-caption" width="150">
                                                            % Contribution to End of Term
                                                        </v-card>
                                                    </v-list-item-content>    
                                                    <v-card flat width="70">
                                                        <v-text-field
                                                            v-model="participationPercentage"
                                                            outlined
                                                            hide-details
                                                            dense
                                                            class="mb-3"
                                                            disabled
                                                            filled
                                                        ></v-text-field>
                                                    </v-card>
                                                </v-list-item>
                                            </v-list>
                                        
                                        </v-card-text>
                                        <v-card-actions class="px-8">
                                            <v-spacer></v-spacer>
                                            <v-btn 
                                                depressed
                                                @click="menu = false"
                                            >
                                                Close
                                            </v-btn>
                                            <v-btn
                                                depressed
                                                color="primary"
                                                @click="updateRecord()"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>

                                <v-text-field
                                    v-else-if="!item.select && item.max"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    min=0
                                    :max="item.max"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="30"
                                    class="text-caption pa-0"
                                    type="number"
                                    oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min"
                                ></v-text-field>

                                <v-text-field
                                    v-else-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    @input="checkNumber(item.model)"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="30"
                                    class="text-caption pa-0"
                                    type="number"
                                ></v-text-field>

                            </v-card> 
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row class="mr-0">
            <v-col class="px-0">
                <v-card
                    flat                
                    class="px-3" 
                    :width="width"               
                >
                    <v-row 
                        class=""
                    >
                        <v-col class="py-0 pr-0">
                            <v-textarea                                       
                                label="Teacher's comments"  
                                v-model="studentRecord.comments"                          
                                persistent-hint
                                dense
                                rows="8"
                                counter                                            
                                class="text-caption"
                                no-resize
                                maxlength="255"
                                :readonly="disabledDeanComment"
                                :filled="disabledDeanComment"
                                @blur="updateRecord"
                                outlined
                                :disabled="disabled"
                            >                        
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mr-0">
            <v-col class="px-0">
                <v-card
                    flat
                    class="pl-3 "
                    :width="width"
                >
                    <v-row class="px-3">
                        <v-col class="pa-0 d-flex justify-space-between">
                            <v-btn 
                                class="text-caption pa-2  white--text"
                                width="15"
                                height="30"                
                                color="primary"
                                @click="previousRecord"
                                :disabled="!pagination.prev_page"                
                            >
                                <div class="d-flex justify-center align-center pr-2"> 
                                    <v-icon class="">mdi-chevron-left</v-icon>
                                    <span>PREV</span>
                                </div>                                         
                            </v-btn>
                        
                            <v-btn 
                                class="text-caption pa-2"
                                width="20"
                                height="30"
                                outlined
                                disabled                
                                text                
                            >
                                <span class="blue--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                            </v-btn>
                        
                            <v-btn 
                                class="text-caption pa-2  white--text"
                                width="20"
                                height="30"
                                dark
                                color="primary"
                                @click="nextRecord"
                                :disabled="!pagination.next_page"
                            >
                                <div class="d-flex justify-center align-center pl-2">
                                    <span>NEXT</span>
                                    <v-icon>mdi-chevron-right</v-icon>
                                </div>                                         
                            </v-btn>
                        </v-col>
                    </v-row>    
                </v-card>    
            </v-col>
        </v-row>

       

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
            deanFormClasses: 'termReports/getDeanFormClasses',
        }),

        participationTotal () {
            let total = 0;
            this.participationAttributes.forEach(attribute => {
                total += parseInt(this.studentRecord[attribute.model], 10) || 0;
            })
            return total;
        }, 
       
        studentName () {
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        className () {
            return this.studentRecord.class_name_title;
        },

        recordSummary () {
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                {title: 'Possible Attendances', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'Times Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'Number of Times Late', model: 'times_late', select: false, disabled: false, section: 1},
                {title: 'Project', model: 'project', select: false, disabled: false, section: 1, max: 25},
                {title: 'Participation', model: 'participation', select: false, disabled: false, section: 1, max: 5, menu: true},
            ]
        },

        termName () {
            let termName = '';
            if(this.currentTerm == 1) termName =  '1';
            if(this.currentTerm == 2) termName = '2';
            if(this.currentTerm == 3) termName = '3';
            return termName
        },

        extraCurricularRows () {
            return this.extraCurricular.filter(value => value.sectionRow);
        },       
        
        
    },
    watch: {
        studentRecord: {
            handler () {                
                this.setDisabledFields();
                this.updateParticipationPercentage();
                console.log(this.studentRecord)
            }
        },

    },
    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
        ],
       
        options: {
            itemsPerPage: -1,
        },  
        disabled: false,
        expand: false,
        overlay: false,
        search: '',
        width: '240', 
        disabledDeanComment: false,
        comments: [],
        participationAttributes: [
            { id: 1, title: 'Shares, cooperates, collaborates', model: 'shares_cooperates', max: 5 },
            { id: 2, title: 'Listens, actively', model: 'listens_actively', max: 5 },
            { id: 3, title: 'Is persistent', model: 'persistent', max: 5 },
            { id: 4, title: 'Accepts challenges', model: 'accepts_challenges', max: 5 },
            { id: 5, title: 'Is prepared for work', model: 'prepared', max: 5 },
        ],
        menu: false,
        participationPercentageContribution: 0,
        participationPercentage: 0,
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',
            getCodedComments: 'termReports/getCodedComments',            
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
            setParticipationPercentage: 'termReports/setParticipationPercentage',
        }),

        async initialize(){ 
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;  
        },

        async updateRecord(){
            console.log('updating...');
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },


        async recordNavigation(pagination){
            let response = null;
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);           
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            // console.log('previous');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
            // console.log('next');
        },

        cardWidth (item, index ){
            if(index == 1) return '100px';
            if(item.type == 'select') return '100px'
            return '65px'
        },

        setDisabledFields () {
            this.disabled = false;
            this.recordSummary.forEach(element => {
                element.disabled = false;
                if(element.model == 'student_id' || element.model == 'possible_attendance')
                element.disabled = true;
            })

            let found = this.formTeacherClass.some(element => {
                return element.form_class_id === this.studentRecord.form_class_id 
            });

            console.log('found: ', found)
            if(!found && !this.admin){
                console.log('Student Details Not the form Teacher');
                this.disabled = true;
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })                    
            }
        },

        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        checkNumber (model) {
            const validNumber = /^[0-9]+$/.test(this.studentRecord[model]);
            console.log(validNumber)
            if(!validNumber){
                this.studentRecord[model] = null;
            }
        },

        updateParticipationPercentage () {
            let total = 0;
            let totalMax = 0;
            this.participationAttributes.forEach(attribute => {
                total += parseInt(this.studentRecord[attribute.model], 10) || 0;
                totalMax += parseInt(attribute.max, 10) || 0;
            })
            this.participationPercentage = totalMax > 0 ? Math.round(total / totalMax * 5) : 0;
            this.setParticipationPercentage(this.participationPercentage);
        },



    },

   
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 32px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
        padding-left: 0 !important;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
    }

    /* ::v-deep .times_late .v-chip.v-size--default{
        margin-top: 0;
    } */

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }

    ::v-deep .moral-education .v-input .v-input__control .v-input__slot{
        padding: 0;
    }

    ::v-deep .moral-education .v-input .v-input__control .v-select__selections .v-chip{
        font-size: 10px;
        height: 20px;
    }

    ::v-deep .moral-education .v-input{
        width: 75px;
    }

    ::v-deep .moral-education .v-input .v-input__control .v-input__slot .v-select__slot .v-select__selections .v-chip--select .v-chip__content .v-icon{
        margin-left: 2px;
    }

    ::v-deep .v-textarea textarea{
        line-height: 1.2rem;
    }

    /* ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    } */

    /* ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }     */
</style>