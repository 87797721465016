<template>
    <v-dialog
        v-model="dialog"
        max-width="900px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
             <v-btn
                x-small
                text
                outlined
                class="mr-2"
                v-bind="attrs"
                v-on="on"
            >
                <span
                    class="mr-2"
                >Lessons</span>
                <v-icon
                    x-small
                    class="mr-2 "

                >
                    mdi-bookshelf
                </v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                {{ teacherSelected }}
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-autocomplete
                            v-model="formTeacherClasses"
                            :items="formClasses"
                            outlined

                            label="Select Teacher Class"
                            @change="updateFormClass"
                            chips
                            small-chips
                            multiple
                            item-text="class_name"
                            item-value="form_class_id"
                            deletable-chips
                            hide-details
                        >
                            <template v-slot:item="data">
                                {{ data.item.class_name }}
                            </template>

                            <template v-slot:selection="data">
                                <v-chip
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    close
                                    @click="data.select"
                                    @click:close="remove"
                                >
                                    {{ data.item.class_name }}
                                </v-chip>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-data-table
                            :headers="headers"
                            :items="lessons"
                            fixed-header
                            :loading="loadingLessons"
                            height="40vh"
                            :calculate-widths="true"
                            ref="LessonTable"
                            class="lesson-table"
                            :disable-pagination="true"
                            :options="options"
                        >
                            <template v-slot:item="props">
                                <LessonRow
                                    v-bind:lesson="props.item"
                                    v-bind:lessonClasses="formClasses"
                                    v-bind:lessonSubjects="subjects"
                                    v-bind:index="props.index"
                                    @update-lessons="updateLessons"
                                />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-6 pb-4">
                <span>
                    <span>{{ saveProgress }}</span>
                    <v-progress-circular
                        indeterminate
                        size="16"
                        width="3"
                        v-if="saveStatus.saving"
                        class="ml-2"
                    ></v-progress-circular>
                    <v-icon
                        small
                        color="green"
                        v-else-if="saveStatus.saved"
                        class="mx-2"
                    >
                        mdi-check-all
                    </v-icon>
                    <v-icon
                        small
                        color="red"
                        v-else-if="saveStatus.error"
                        class="mx-2"
                    >
                        mdi-alert-circle
                    </v-icon>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                    @click="closeEditLesson"
                    depressed
                >
                    Close Form
                </v-btn>
                <v-btn
                    color="primary"
                    @click="addLesson"
                    depressed
                >
                    Add Lesson
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveLessons"
                    depressed
                >
                    Save Lessons
                </v-btn>
            </v-card-actions>
            <v-overlay
                absolute
                :value="overlay"
                color="secondary"
                opacity="0.8"
            >
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LessonRow from './AdminEmployeesLessonsRecord';
export default {
    components: {
        LessonRow
    },

    props: {
        employee: Object,
        formClasses: Array,
    },

    data: function () {
        return {
            headers: [
                {text: 'Subj ID', value: 'subjectId', width: '100', sortable: 'false'},
                {text: 'Subject', value: 'subject', width: '220', sortable: 'false'},
                {text: 'Class', value: 'formClass', width: '240', sortable: 'false'},
                {text: '', value: 'actions', sortable: 'false', width: '30'},
                {text: '', value: 'status', sortable: 'false', width: '30'},
            ],
            formTeacherClasses: null,
            dialog: false,
            lessons: [],
            loadingLessons: true,
            options: {
                itemsPerPage: -1,
            },
            saveProgress: '',
            saveStatus: {
                saving: false,
                saved: false,
                error: false,
            },
            defaultLesson: {
                id: '',
                subjectId: '',
                subject: '',
                employeeId: '',
                formClasses: [],
            },
            blankLesson: {},
            defaultFormClassRecord: {
                id: '',
                employee_id: '',
                class_id: '',
                academic_year_id: '',
            },
            formClassRecord: {},
            subjects: [],
        }
    },

    computed: {
        ...mapGetters({
            overlay: 'admin/getLessonOverlay',
            teacherLessons: 'admin/getTeacherLessons',
        }),
        teacherSelected(){
            return this.employee?.first_name + ' ' + this.employee?.last_name;
        },
    },

    watch: {
      //
      teacherLessons: {
        handler () {
            // console.log(val)
        }
      },

      dialog: {
        handler (val) {
            //console.log(val);
            if(val) this.initialize();
        }
      }
    },

    methods: {
        ...mapMutations({
            setSelectedEmployeeId: 'admin/setSelectedEmployeeId',
            setOverlay: 'admin/setLessonOverlay',
            setTeacherLessons: 'admin/setTeacherLessons',
            setFormTeacherClasses: 'admin/setFormTeacherClasses',
        }),

        ...mapActions({
            getSubjects: 'admin/getSubjects',
            getTeacherLessons: 'admin/getAdminTeacherLessons',
            getFormTeacherClass: 'admin/getFormTeacherClass',
            postFormTeacherAssignment: 'admin/postFormTeacherAssignment',
        }),

        async initialize()
        {
            // console.log('initializing employee lessons...')
            this.lessons = [];
            this.saveProgress = '';
            this.blankLesson = {...this.defaultLesson};
            this.blankLesson.employeeId = this.employee.id;
            this.formClassRecord.classes = '';

            this.setSelectedEmployeeId(this.employee.id);
            this.setOverlay(true);
            this.editedEmployee = Object.assign({}, this.employee);
            try {
                const [
                    { data: dataSubjects },
                    { data: dataTeacherLessons },
                    { data: dataFormTeacherClasses },
                ] = await Promise.all([
                    this.getSubjects(),
                    this.getTeacherLessons(),
                    this.getFormTeacherClass(),
                ])

                dataSubjects.forEach(record => {
                    let subject = {
                        id: record.id,
                        title: record.title
                    }
                    this.subjects.push(subject);
                })

                this.lessons =
                dataTeacherLessons.map(record => {
                    return {
                        id: record.id,
                        subjectId: record.subject_id,
                        subject: record.subject_title,
                        formClasses: record.form_classes,
                        employeeId: this.employee.id
                    }
                })

                this.formTeacherClasses = dataFormTeacherClasses[0]

                this.loadingLessons = false;
                this.setOverlay(false);

            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
            }
        },

        addLesson(){
            this.lessons.push(this.blankLesson);
            this.$nextTick(() => {
                this.scrollToBottom();
            })
        },

        scrollToBottom () {
            const dataTable = this.$refs.LessonTable.$el.querySelector('.v-data-table__wrapper');
            if(dataTable){
                const lastRow = dataTable.querySelector('tbody tr:last-child');
                if (lastRow) {
                    lastRow.scrollIntoView({ behavior: 'smooth', block: 'end' });
                }
            }
        },

        closeEditLesson(){
            this.lessons = [];
            this.dialog = false;
        },

        async updateFormClass(){
            // console.log('updating form class...');
            this.saveProgress = 'Saving ';
            this.setSaveStatus()

            this.setFormTeacherClasses(this.formTeacherClasses)

            try {
                await this.postFormTeacherAssignment();
                await this.initialize();
                this.saveProgress = 'Saved ';
                this.setSaveStatus('saved')
                this.$emit('update-form-classes')

            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.saveProgress = 'Error Occured';
                this.setSaveStatus('error');
            }
        },

        updateLessons (data) {
            let { index, lesson, deleteLesson } = data;

            if(deleteLesson){
                let lessons = this.lessons.slice(0,index).concat(this.lessons.slice(index+1));
                this.lessons = [];
                this.$nextTick(() => {
                    this.lessons = lessons;
                })
                return;
            }

            this.lessons[index] = lesson;

        },

        saveLessons () {
            this.setOverlay(true);
            setTimeout(() => {
                this.setOverlay(false);
            }, 500)
        },

        remove () {
            console.log('removing item...')
            //post requires an array
            this.formTeacherClasses = [];
            this.updateFormClass()

        },

        setSaveStatus (status = 'saving') {
            Object.keys(this.saveStatus).forEach(key => {
                this.saveStatus[key] = false;
                if(key == status) this.saveStatus[key] = true;
            })
            // console.log(status)
        }
    }
}
</script>