<template>
    <v-container fluid>
        <v-card
            class="mx-auto"
            max-width="600"
        >
            <v-card-title
                class="white--text primary"
            >
                Student Registration
            </v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="classLevels"
                    :loading="loading"
                >
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            @click="editStatus(item)"
                            color="primary"
                            small
                            outlined
                        >
                            Edit
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>            
        </v-card>

        <v-dialog
            v-model="dialog"
            max-width="400"
            persistent            
        >
            <v-card >
                <v-card-title>
                    {{ cardTitle }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="editedFormLevel.open"
                                :items="statusOptions"
                                label="Registration Status"
                                item-text="status"
                                item-value="open"
                                outlined
                                @change="changeStatus"
                            ></v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <date-picker
                                label="Deadline Date"
                                fontSize="1rem"
                                :clearable="true"
                                :disabled="disableDeadline"
                                v-on:change-date="setDeadlineDate"
                                :initialDate="editedFormLevel.deadline"
                            ></date-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        depressed
                        color="primary"
                        @click="update"
                    >Update</v-btn>
                    <v-btn
                        outlined
                        color="primary"
                        @click="close"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
                <v-overlay
                    :value="overlay"
                    opacity="0.8"                    
                    absolute                  
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-card>
        </v-dialog>    
        <v-snackbar
            v-model="snackbar.visible"
            :color="snackbar.color"
        >
            {{ snackbar.text }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar.visible = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
// import DatePickerRange from './DatePickerRange'
import DatePicker from './DatePicker';
export default {
    components: {
        // DatePickerRange,
        DatePicker
    },

    mounted: function () {
        this.initialize();
    },

    computed: {
        disableDeadline () {
            if(this.editedFormLevel.open === 1) return false;            
            return true;
        }
    },
    
    data: () => ({
       headers: [
            {
                text: 'Class',
                align: 'start',
                sortable: false,
                value: 'class_name'
            },
            {
                text: 'Registration Status',
                align: 'start',
                sortable: false,
                value: 'status'
            },
            {
                text: 'Deadline Date',
                align: 'start',
                sortable: false,
                value: 'deadline'
            },
            {
                text: 'Actions',
                align: 'start',
                sortable: false,
                value: 'actions'
            },
       ],
       classLevels: [],
       loading: false,
       dialog: false,
       cardTitle: null,
       statusOptions:[
           {open: 0, status: "Close"},
           {open: 1, status: "Open"},
       ],
       editedFormLevel: {},
       overlay: false,
       snackbar: {
           text: null,
           visible: false,
           color: "primary"
       },
    }),   

    methods: { 
        ...mapActions({
            getStudentRegistration: 'admin/getStudentRegistration',
            postStudentRegistrationStatus: 'admin/postStudentRegistrationStatus',
        }),
        
        ...mapMutations({
            setStudentRegistrationAccess: 'admin/setStudentRegistrationAccess',
        }),

        async initialize () {
            this.loading = true;
            try {
                const { data } = await this.getStudentRegistration();
                this.mapRegistrationStatus(data);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
        },

        
        mapRegistrationStatus (data) {
            this.classLevels = [];
            data.forEach(value => {
                value.status = 'Open';
                if(value.open === 0) value.status = 'Closed'
                this.classLevels.push(value);
            })
        },

        editStatus (item) {
            // console.log(item)
            this.dialog = true;
            this.cardTitle = `Form ${item.form_level} Registration Status`;
            this.editedFormLevel = item;
        },

        close () {
            this.dialog = false;
        },

        setDeadlineDate (date) {
            // console.log(date);
            this.editedFormLevel.deadline = date;
        },

        changeStatus () {
            if(this.editedFormLevel.open === 0)
            this.editedFormLevel.deadline = null;
        },

        async update () {
            this.overlay = true;
            this.setStudentRegistrationAccess(this.editedFormLevel);
            console.log(this.editedFormLevel);
            try {
                const response = await this.postStudentRegistrationStatus();
                console.log(response);
                this.initialize();
                this.overlay = false;                
                this.snackbar.text = "Data updated.";
                this.snackbar.color = "primary";
            } catch (error) {
                this.snackbar.text = "Error Occured Data not updated.";
                this.snackbar.color = "red";
                this.overlay = false;
                if(error.response) {
                    console.log(error.response);
                    return;
                }
                console.log(error);                
            }
            this.dialog = false;
            this.snackbar.visible = true;
        }
    }
}
</script>