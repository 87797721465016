import { render, staticRenderFns } from "./EditViewTermReportsBehaviour.vue?vue&type=template&id=d09fb634&scoped=true&"
import script from "./EditViewTermReportsBehaviour.vue?vue&type=script&lang=js&"
export * from "./EditViewTermReportsBehaviour.vue?vue&type=script&lang=js&"
import style0 from "./EditViewTermReportsBehaviour.vue?vue&type=style&index=0&id=d09fb634&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d09fb634",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VChip,VDataTable,VSelect,VSheet})
