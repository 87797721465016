<template>
    <div>
        <v-expand-transition>                            
            <v-card                
                width="500"
                class="mx-auto"
                transition="scroll-y-transition"
                v-show="expand"
                light 
            >
                
                <v-toolbar
                    color="primary"
                    dark
                >
                    <v-toolbar-title>
                        <v-btn
                            v-if="previous"
                            icon
                            @click="previousOption"
                        >
                            <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                        {{ toolbarTitle }}
                    </v-toolbar-title>
                </v-toolbar>
                <div v-if="expand" style="max-height: 500px;  overflow-y: auto">
                    <v-window v-model="step" class="pa-4">
                        <v-window-item :value="1">    
                            <v-card flat height="400">
                                <v-list 
                                    light                    
                                >
                                    <v-list-group
                                        v-for="item in formClasses"
                                        :key = "item.title"
                                        v-model="item.active"
                                        :prepend-icon="item.action"
                                        no-action
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{ item.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                        <v-list-item
                                            v-for="subItem in item.items"
                                            :key="subItem.title"
                                            @click="setSelectedClass(subItem)"                                       
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    {{ subItem.title }}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-group>
                                </v-list>
                            </v-card>
                        </v-window-item>
                            <v-window-item :value="2">
                                <v-card flat height="400">                        
                                    <v-data-table                        
                                        :headers="headers"
                                        :items="subjects"
                                        :search="search"
                                        hide-default-footer
                                        fixed-header
                                        :items-per-page="itemsPerPage"
                                        height="300px"
                                        @click:row="displayMarkSheet"
                                        
                                    >
                                        <template v-slot:top>
                                            <v-toolbar flat>
                                                <v-text-field
                                                    v-model="search"
                                                    append-icon="mdi-magnify"
                                                    label="Search Subject"
                                                    single-line
                                                    hide-details
                                                    clearable
                                                    class="mb-8"
                                                ></v-text-field>
                                            </v-toolbar>
                                        </template>
                                    </v-data-table>
                                </v-card>
                        </v-window-item>
                    </v-window>                        
                </div>      
            </v-card>
        </v-expand-transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        nextSubject: Boolean,
    },
    data: () => ({
        expand: false,        
        selectFormClass: false,
        selectSubject: false,
        search: '',
        headers: [
            {text: 'Subj Code', value: 'id', width: '120'},
            {text: 'Subject', value: 'title'},
        ],
        subjects: [],
        itemsPerPage: -1,
        step: 1,
        toolbarTitle: null,
        previous: false,
    }),

    mounted: function () {
        if(this.admin ) this.initialize();
    },

    watch: {
        //
    },

    computed: {
        ...mapGetters({
            formClasses: 'termReports/getFormClasses',
            admin: 'auth/getAdmin',
            menuOptions: 'admin/getMenuOptions',
            markSheetAdminSelectedClass: 'termReports/getMarkSheetAdminSelectedClass',
        })
    },

    methods: {
        ...mapMutations({           
            setLessonSelected: 'termReports/setLessonSelected',
            setMarkSheetLoading: 'termReports/setMarkSheetLoading',
            setFormClasses: 'termReports/setFormClasses',
            setMarkSheetAdminSelectedClass: 'termReports/setMarkSheetAdminSelectedClass',
        }),

        ...mapActions({
            getSubjects: 'admin/getSubjects',
            getStudents: 'termReports/getStudents',
            getFormClasses: 'termReports/getFormClasses',
        }),

        setSelectedClass (val) {
            this.setMarkSheetAdminSelectedClass(val);
            this.toolbarTitle = "Select Subject";
            this.previous = true;
            this.step = 2;
        },

        async initialize () { 
            this.step = 1;
            try {
                const { data } = await this.getFormClasses();
                this.mapFormClasses(data)
                const { data:dataSubjects } = await this.getSubjects();
                this.subjects = dataSubjects;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            
            this.setMarkSheetLoading(false);
            
            if(this.nextSubject){
                this.toolbarTitle = "Select Subject"
                this.expand = true;
                this.step = 2;
                this.previous = true;
                return;
            }

            this.$nextTick(() => {
                this.toolbarTitle = "Select Class"
                this.expand = true;                    
            })
            
        },

         mapFormClasses (data) {
            let formClasses = [];
            let infantOneClasses = [];
            let infantTwoClasses = [];
            let standardOneClasses = [];
            let standardTwoClasses = [];
            let standardThreeClasses = [];
            let standardFourClasses = [];            
            let standardFiveClasses = []; 
            data.forEach(record => {
                switch(record.class_name){
                    case 'Infant One':
                    if(record.form_teachers){
                        infantOneClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    infantOneClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Infant Two':
                    if(record.form_teachers){
                        infantTwoClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    infantTwoClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Standard One':
                    if(record.form_teachers){
                        standardOneClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    standardOneClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Standard Two':
                    if(record.form_teachers){
                        standardTwoClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    standardTwoClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Standard Three':
                    if(record.form_teachers){
                        standardThreeClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    standardThreeClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Standard Four':
                    if(record.form_teachers){
                        standardFourClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    standardFourClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;

                    case 'Standard Five':
                    if(record.form_teachers){
                        standardFiveClasses.push({
                            title: record.class_name + ' - ' + record.form_teachers,
                            class_name: record.class_name,
                            class_group: record.class_group,
                            form_class_id: record.form_class_id
                        });
                        break;
                    }
                    standardFiveClasses.push({
                        title: record.class_name + ' Group ' + record.class_group,
                        class_name: record.class_name,
                        class_group: record.class_group,
                        form_class_id: record.form_class_id
                    });
                    break;
                }                
            });

            if(infantOneClasses.length != 0){
                formClasses.push({
                    title: 'Infant One',
                    action: 'mdi-school',                
                    items: infantOneClasses
                });
            }

            if(infantTwoClasses.length != 0){
                formClasses.push({
                    title: 'Infant Two',
                    action: 'mdi-school',                
                    items: infantTwoClasses
                });
            }

            if(standardOneClasses.length != 0){
                formClasses.push({
                    title: 'Standard One',
                    action: 'mdi-school',                
                    items: standardOneClasses
                });
            }

            if(standardTwoClasses.length != 0){
                formClasses.push({
                    title: 'Standard Two',
                    action: 'mdi-school',                
                    items: standardTwoClasses
                });
            }

            if(standardThreeClasses.length != 0){
                formClasses.push({
                    title: 'Standard Three',
                    action: 'mdi-school',                
                    items: standardThreeClasses
                });
            }

            if(standardFourClasses.length != 0){
                formClasses.push({
                    title: 'Standard Four',
                    action: 'mdi-school',                
                    items: standardFourClasses
                });            
            }

            if(standardFiveClasses.length != 0){
                formClasses.push({
                    title: 'Standard Five',
                    action: 'mdi-school',                
                    items: standardFiveClasses
                }); 
            }

            this.setFormClasses(formClasses)

            // this.setLoading(false)
            // setTimeout(() => {
            //     this.setExpand(true)
            // }, 700)
        },


        async displayMarkSheet (val) {
            this.setLessonSelected({
                classId: this.markSheetAdminSelectedClass.form_class_id,
                subjectCode: val.id,
                subjectTitle: val.title,
                className: this.markSheetAdminSelectedClass.title,

            });
            this.expand = false;
            this.step = 1;            
            setTimeout(() => {
                this.setMarkSheetLoading(true);
            }, 700)            
            await this.getStudents(this.id);   
        },

        previousOption () {
            this.step = 1; 
            this.previous = false
            this.toolbarTitle = "Select Class"
        }
    }
}
</script>

<style scoped>
    ::v-deep tr {
        cursor: pointer;
    }
</style>