<template>
     <v-dialog
        v-model="dialog"
        max-width="1020px"
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="initialize"
                small
                class="mr-3"
            >Upload Students</v-btn>            
        </template>
        <v-expand-transition>
            <v-card
                v-show="expandFileSelect"
            >
                <v-card-title>
                    Upload Students
                    <v-btn
                        icon
                        class="ml-auto"
                        @click="close"
                    >
                        <v-icon color="red">mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col class="px-0">
                                <v-file-input
                                    v-model="file"
                                    show-size
                                    label="Click to select file to upload."
                                    @change="selectFile"
                                    outlined
                                    dense
                                ></v-file-input>
                            </v-col>    
                        </v-row>
                        <v-row v-if="file">
                            <v-progress-linear
                                v-model="progress"
                                color="primary"
                                height="25"
                                reactive
                            >
                                <strong>{{ progress }} %</strong>
                            </v-progress-linear>
                        </v-row>

                        <v-alert v-if="message" border="left" :color="messageColor" dark>
                            {{ message }}
                        </v-alert>

                    </v-container>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined
                        class="mr-2"
                        color="primary"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        outlined
                        class="mr-2"
                        color="primary"
                        @click="downloadTemplate"
                    >
                        Download Template
                    </v-btn>
                    <!-- <v-btn
                        v-if="file"
                        color="primary"
                        dark
                        @click="uploadFile"
                    >Upload File</v-btn> -->
                </v-card-actions>
                <v-overlay
                    :value="overlayDownload"
                    :absolute="absolute"
                    color="#fff"
                    opacity="0.9"
                >
                    <v-progress-circular                        
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-expand-transition>

        <v-expand-transition>
            <v-card
                v-show="expandConfirmUpload"
                max-height="95vh"
            >
                <v-card-title>
                    {{ uploadConfirmText }}
                </v-card-title>
                <v-alert
                    v-if="alert.display"
                    prominent
                    type="error"
                    dense
                >
                    {{ alert.text }}
                </v-alert>
                <v-card-text>
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="students"                    
                        height="45vh"
                        fixed-header
                        :items-per-page="itemsPerPage"
                        :loading="loading"
                        :show-select="showSelect"
                    >
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                                v-if="item.error"
                                small
                                color="red"                            
                            >
                                mdi-alert-circle
                            </v-icon>
                            <v-icon
                                v-else
                                small
                                color="green"                            
                            >
                                mdi-check-all
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions
                    class="px-6"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeDeleteRecords"
                    >
                        <v-icon left>mdi-arrow-left</v-icon>
                        Back
                    </v-btn>
                    <v-btn
                        v-if="deleteRecords"
                        color="primary"
                        dark
                        small
                        class="mr-2"
                        @click="deleteSelectedRecords"
                    >
                        Delete
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="selectRecords"
                    >
                        Delete Records
                    </v-btn>
                    <v-btn
                        v-if="!uploadComplete && !deleteRecords"
                        color="primary"
                        dark
                        small
                        @click="uploadConfirm"
                    >
                        Confirm Upload
                    </v-btn>
                    <v-btn
                        v-if="uploadComplete && !deleteRecords"
                        color="primary"
                        outlined
                        small
                        class="mr-2"
                        @click="closeUploadConfirm"
                    >
                        Close
                    </v-btn>
                </v-card-actions>

                <v-overlay
                    :value="overlay"
                    :absolute="absolute"
                    color="#fff"
                    opacity="0.9"
                >                
                    <v-card                    
                        flat
                        width="400"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ uploadingText }}
                            </v-col>
                            <v-col 
                                cols="6"
                                v-show="!uploadComplete"
                            >
                                <v-progress-linear
                                    v-model="progress"
                                    height="6"                        
                                    color="primary"
                                    indeterminate
                                    rounded
                                >                        
                                </v-progress-linear>
                            </v-col>
                            
                        </v-row>                    
                    </v-card>
                </v-overlay>

                <v-overlay
                    :value="overlayDeleteConfirm"
                    :absolute="absolute"
                    color="#fff"
                    opacity="0.9"
                >
                    <v-card 
                        class="pa-2"
                        width="300"
                        light
                    >
                        <v-row
                            align-content="center"
                            justify="center"
                        >
                            <v-col 
                                cols="12"
                                class="subtitle-1 text-center"
                            >
                                {{ deleteRecordsMessage }}
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                outlined
                                class="mr-4"
                                color="primary"
                                small
                                @click="cancelDeleteRecords"
                            >
                                Cancel
                            </v-btn>
                            <v-btn                            
                                color="primary"
                                dark
                                small
                                @click="deleteSelectedRecordsConfirm"                            
                            >
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>        
                </v-overlay>

                <v-snackbar
                    v-model="snackbar.display"
                    :color="snackbar.color"
                >
                    {{ snackbar.text }}
                </v-snackbar>
            </v-card>
        </v-expand-transition>    
     </v-dialog>        
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import UploadService from "../services/UploadFileService";
export default {
    computed: {
        headers () {
            if(!this.uploadComplete)
            return [
                {text: 'Student ID', value: 'id', width: 90, sortable: false},
                {text: 'Last Name', value: 'last_name', sortable: false},
                {text: 'First Name', value: 'first_name', sortable: false},
                {text: 'SEA Reg No.', value: 'sea_no', sortable: false},
                {text: 'Class', value: 'class_name', width: 180, sortable: false},                
                {text: 'DOB', value: 'date_of_birth', width: 120, sortable: false},               
                {text: 'House', value: 'house_name', sortable: false},                
                {text: 'Entry Date', value: 'entry_date', sortable: false, width: 120},                
            ];
            else return [
                {text: 'Student ID', value: 'id', width: 90, sortable: false},
                {text: 'Last Name', value: 'last_name', sortable: false},
                {text: 'First Name', value: 'first_name', sortable: false},
                {text: 'SEA Reg No.', value: 'sea_no', sortable: false},
                {text: 'Class', value: 'class_name', width: 180, sortable: false},
                {text: 'DOB', value: 'date_of_birth', width: 120, sortable: false},               
                {text: 'House', value: 'house_name', sortable: false},                
                {text: 'Entry Date', value: 'entry_date', sortable: false, width: 120},  
                {text: 'Uploaded', value: 'actions', sortable: false},
            ]
        }
    },

    data: () => ({
        dialog: false,
        file: null,
        progress: 0,        
        message: '',
        messageColor: '',
        expandFileSelect: false,
        expandConfirmUpload: false,
        students: [],        
        itemsPerPage: -1,
        loading: false,
        absolute: true,
        overlay: false,
        overlayDeleteConfirm: false,
        uploadingText: null,
        uploadingMessages: [
            'Please wait...',
            'Working on your request...',
            'Taking a bit long...',
            'Still working...'
        ],
        uploadComplete: false,
        uploadConfirmText: '',
        showSelect: false,
        selected: [],
        deleteRecords: false,
        deleteRecordsMessage: '',        
        overlayDownload: false,
        snackbar: {
            display: false,
            text: null,
            color: 'primary'
        },
        alert: {
            display: false,
            text: null,
        }
    }),

    watch: {
        file: {
            handler (val) {
                if(val) this.uploadFile();
            }
        }
    },

    methods: {
        ...mapActions({
            getDownloadTemplate: 'admin/dowloadRegistrationTemplate',
            uploadStudents: 'admin/uploadStudents',
        }),

        ...mapMutations({
            setNewStudents: 'admin/setNewStudents',
        }),

        initialize () {
            this.expandFileSelect = true;
        },        

        downloadTemplate () {
            this.overlayDownload = true;            
            this.getDownloadTemplate()
               .then(response => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    link.setAttribute('download', 'Registration Upload.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    this.overlayDownload = false;            //         
               })
               .catch( error => {
                   if(error.response) console.log(error.response);
                   else console.log(error);
                   this.overlayDownload = false;
                   
               })

        },        

        selectFile (file) {
            this.progress = 0;
            this.file = file;
            this.message = null;
        },

        async uploadFile () {
            if(!this.file) {
                this.messageColor = "red"
                this.message = "Please select a file!";
                return;
            }

            this.message = "";
            this.loading = true;

            try {
                const { data } = await UploadService.upload(this.file, (event) => {
                    this.progress = Math.round((100 * event.loaded) / event.total);
                })
                this.students = data;

                setTimeout(() => {
                    this.expandFileSelect = false;
                    this.loading = false;
                }, 700)

                setTimeout(() => {
                    this.expandConfirmUpload = true;
                    this.uploadConfirmText = "Confirm Upload Students"
                }, 1400);
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.progress = 0;
                this.message = "Could not upload the file.";
                this.messageColor = 'red'
                this.file = null;
            }

        },
       

        deleteSelectedRecords () {
            this.deleteRecordsMessage = `Delete ${this.selected.length} record ?`;
            if(this.selected.length > 1)
            this.deleteRecordsMessage = `Delete ${this.selected.length} records ?`;
            this.overlayDeleteConfirm = true;           
           
        },

        cancelDeleteRecords () {
            this.overlayDeleteConfirm = false;
            this.deleteRecordsMessage = '';
        },

        deleteSelectedRecordsConfirm () {
            this.cancelDeleteRecords();
            this.selected.forEach(value => {                
                this.students.splice(this.students.indexOf(value), 1);
            })
            this.selected = [];
        },

        selectRecords () {
            this.showSelect = true;
            this.deleteRecords = true;
            this.uploadConfirmText = "Select Records To Delete"
        },

        closeDeleteRecords () {
            this.deleteRecords = false;
            this.showSelect = false;
            this.uploadConfirmText = "Confirm Upload Students"
        },

        async uploadConfirm () {
            this.overlay = true;
            this.uploadComplete = false;
            this.uploadingText = "Uploading Students."
            // this.progress = 10;
            let messageCount = this.uploadingMessages.length;
            let index = 0;
            let messageInterval = setInterval(() => {
                if(index < (messageCount - 1)) index++;
                else index = 0;
                this.uploadingText = this.uploadingMessages[index];
                // console.log(`index: ${index}, text: ${this.uploadingMessages[index]}`)
            }, 3000);
            this.setNewStudents(this.students); 
            try {
                const { data } = await this.uploadStudents();
                console.log(data)
                if(this.uploadErrorCheck(data)) {
                    this.alert.text = "Some records could not be uploaded";
                    this.alert.display = true;
                }
                this.uploadConfirmText = "Upload Complete"
                this.students = data;
                clearInterval(messageInterval);
                this.uploadingText = "Upload Complete.";
                this.uploadComplete = true;
                this.$emit("update-students");
                setTimeout(() => {
                    this.overlay = false
                }, 1000)
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error);
                this.overlay = false;
                this.alert.text = "An Error has occured, data could not be uploaded";
                this.alert.display = true;
            }   
        },

        uploadErrorCheck (data) {
            let hasError = false;
            data.forEach(record => {
                if(record.error !== 0 && record.error != '') {
                    hasError = true;
                }
            })
            return hasError;
        },

        close () {
            this.dialog = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
            this.alert.display = false;
        },

        closeUploadConfirm () {
            this.dialog = false;
            this.alert.display = false;
            this.expandConfirmUpload = false;
            this.expandFileSelect = false;
            this.file = null;
            this.progress = 0;
            this.message = null;
            this.uploadComplete = false;
        }
    }
}
</script>