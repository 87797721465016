<template>
    <tr>
        <td>{{ lessonRecord.subjectId }}</td>
        <td> 
            <v-autocomplete
                v-model="lessonRecord.newSubjectId"
                :items="subjects"
                item-text="title"
                item-value="id"
                outlined
                dense
                hide-details
                @blur="updateLesson"
            ></v-autocomplete>
        </td>
        <td>
            <v-autocomplete
                v-model="selectedClasses"
                :items="computedFormClasses"
                outlined
                dense
                hide-details
                multiple
                chips
                deletable-chips
                small-chips
                @blur="updateLesson"
                @change="onSelectionChange"
                item-text="class_name"
                item-value="form_class_id"
            >
            </v-autocomplete>
        </td>
        <td> 
            <v-icon
                small
                @click="deleteSelectedLesson"
                tabindex="-1"
            >
                mdi-delete
            </v-icon>
        </td>
        <td>            
            <v-progress-circular
                indeterminate
                size="16"
                width="3"
                v-if="saveStatus.saving"
            ></v-progress-circular>
            <v-icon
                small
                color="green" 
                v-else-if="saveStatus.saved"
                class="mr-2"
            >
                mdi-check-all
            </v-icon>
            <v-icon
                small
                color="red"
                v-else-if="saveStatus.error"
            >
                mdi-alert-circle
            </v-icon>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        lesson: Object,
        lessonClasses: Array,
        lessonSubjects: Array,
        index: Number,
    },

    created () {
        this.initialize();
    },

    data: () => ({
        subjects: [],
        formClasses: [],
        lessonRecord: {},
        selectedClasses: [],
        saveStatus: {
            saving: false,
            saved: false,
            error: false,
        },
        selectedSubject: null,
        selectAllOption: { class_name: 'Select All', form_class_id: 'select_all'},
    }),

    computed: {
        ...mapGetters({
            academicYearId : 'admin/getAcademicYearId',
            teacherLessons :  'admin/getTeacherLessons'          
        }),

        computedFormClasses () {
            return [this.selectAllOption, ...this.formClasses];
        },
    },

    watch: {
        lessonRecord: {
            handler (val) {
                if(!val.subjectId){
                    this.lessonRecord.subjectId = this.lessonRecord.newSubjectId
                }
            },
            deep: true,
        },

        lessonClasses: {
            deep: true,
            handler (val) {
                this.formClasses = val.map(value => ({...value}));
            }
        },

        selectedClasses (newSelected) {
            const allOptionsSelected = newSelected.length === this.formClasses.length &&
            newSelected.every(value => this.formClasses.some(option => option.form_class_id === value.form_class_id));

            if (allOptionsSelected) {
                this.selectedClasses = this.formClasses.map(value => value.form_class_id);
            }
        }
    },

    methods: {
        async initialize(){
            this.subjects = [...this.lessonSubjects];
            
            this.formClasses = this.lessonClasses.map(value => ({...value}));
            // this.formClasses = this.formClasses.map(value => {
            //     value.class_name = value.class_name + ' Group ' + value.class_group
            //     return value;
            // });
            
            this.lessonRecord = this.copyObject(this.lesson);
            this.selectedClasses = this.lesson?.formClasses;
            this.lessonRecord.newSubjectId = this.lesson.subjectId;
            this.lessonRecord.academicYearId = this.academicYearId;
            
            this.selectedSubject = {
                id: this.lessonRecord.subjectId,
                title: this.lessonRecord.subject,
            }
        },

        copyObject (obj) {
            let objCopy = {};
            for(let key in obj) {
                if(!Array.isArray(obj[key])){
                    objCopy[key] = obj[key];
                }else{
                    objCopy[key] = [...obj[key]]
                }
            }
            return objCopy;
        },

        ...mapMutations({
            setPostLesson: 'admin/setPostLesson',
            setDeleteLesson: 'admin/setDeleteLesson',
            setTeacherLessons: 'admin/setTeacherLessons',
            setOverlay: 'admin/setLessonOverlay',
        }),

        ...mapActions({
            postLesson: 'admin/postLesson',
            deleteLesson: 'admin/deleteLesson',
            getTeacherLessons: 'admin/getTeacherLessons'
        }),

        updateLesson(){
            if(
                this.selectedClasses.length == 0 ||
                !this.lessonRecord.newSubjectId
            )
            {
                //fields incomplete
                return;
            }
            
            // console.log('selected classes: ', this.selectedClasses);
            this.submitLesson();                
        },

        async submitLesson(){
            this.setSaveStatus()
            this.lessonRecord.formClasses = this.selectedClasses.filter(value => value !== 'select_all');
            let subjectRecord = this.subjects.find(
                subject => subject.id === this.lessonRecord.newSubjectId
            )
            this.lessonRecord.subject = subjectRecord?.title;
            this.setPostLesson(this.lessonRecord);
            try {
                await this.postLesson();
                this.setSaveStatus('saved')
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index, 
                        lesson: this.lessonRecord, 
                        deleteLesson: false 
                    }
                );
               
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.setSaveStatus('error')
            }  
        },

        async deleteSelectedLesson(){ 
            this.setOverlay(true);
            if(!this.lessonRecord.subjectId && this.lessonRecord.formClasses.length == 0){
                //empty record
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index,
                        lesson: null,
                        deleteLesson: true
                    }
                )
                setTimeout(() => {
                    this.setOverlay(false)
                },500)
                return;
            } 
            
            this.setDeleteLesson(this.lessonRecord);
            
            try {
                await this.deleteLesson();
                this.$emit(
                    'update-lessons', 
                    { 
                        index: this.index, 
                        lesson: null, 
                        deleteLesson: true 
                    }
                );
                
               
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.setSaveStatus('error')
            }
            this.setOverlay(false)
        },

        setSaveStatus (status = 'saving') {
            Object.keys(this.saveStatus).forEach(key => {
                this.saveStatus[key] = false;
                if(key == status) this.saveStatus[key] = true;
            })
        },

        onSelectionChange () {
            // console.log('selectedClasses',this.selectedClasses)
            if(this.selectedClasses.includes(this.selectAllOption.form_class_id)) {
                console.log('select all included')
                if(this.selectedClasses.length === 1) {
                    //Select all options
                    this.selectedClasses = [this.selectAllOption.form_class_id, ...this.formClasses.map(value => value.form_class_id)];
                    return;
                }
                //deselect all options
                this.selectedClasses = [];
            }
        },
    }
}
</script>