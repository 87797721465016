<template>
    <tr>
        <td>{{ weeklyTestRecord.count }}</td>
        <td>{{ weeklyTestRecord.name }}</td>
        <td>
            <v-text-field
                v-model="weeklyTestRecord.max_mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                max=100
                disabled
                filled
            ></v-text-field>
        </td>
        <td>
            <v-text-field
                v-model="weeklyTestRecord.mark"
                outlined
                dense
                hide-details
                type="number"
                min=0
                :max="weeklyTestRecord.max_mark"                
                @blur="recordChanged"
                oninput="if(Number(this.value) > this.max) this.value = this.max; else if(Number(this.value) < this.min) this.value = this.min" 
            ></v-text-field>
        </td>
        <td style="text-align:center">{{ percentage }}</td>
        <td style="padding: 0 6px">
            <v-tooltip 
                bottom
                v-if="save.saved"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="green"                        
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                    >
                        mdi-check-all
                    </v-icon>
                </template>    
                <span>Saved</span>                
            </v-tooltip>
            <v-tooltip 
                top
                v-else-if="save.error"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        color="red"                       
                        v-bind="attrs"
                        v-on="on"
                    >
                        mdi-alert-circle
                    </v-icon>
                </template>    
                <span>Error</span>               
            </v-tooltip>
            <v-progress-circular
                indeterminate
                color="primary"
                v-else-if="save.saving"
                size="16"
                width="3"
            ></v-progress-circular>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        record: Object,
        maxMark: Number,
    },

    computed: {
        percentage () {
            if(this.weeklyTestRecord.max_mark && this.weeklyTestRecord.mark)
            return ((this.weeklyTestRecord.mark / this.weeklyTestRecord.max_mark) * 100).toFixed(0);
            return null;
        },

        ...mapGetters({
            employeeId: 'auth/getEmployeeId',
        })
    },

    watch: {
        maxMark: {
            handler (val) {
                this.weeklyTestRecord.max_mark = val
            }
        }
    },

    data: function () {
        return {
            weeklyTestRecord: null,
            save: {
                saving: false,
                saved: false,
                error: false
            }
        }
    },

    created () {
        this.initialize();
    },

    methods: {
        initialize () {
            this.weeklyTestRecord = {...this.record}
        },

        ...mapMutations({
            setWeeklyTestSubjectRecord: 'weeklyTests/setWeeklyTestSubjectRecord',
        }),

        ...mapActions({
            postWeeklyTestSubjectRecord: 'weeklyTests/postWeeklyTestSubjectRecord',
        }),

        async recordChanged () {
            console.log(this.weeklyTestRecord)
            if(this.weeklyTestRecord.mark === this.record.mark) {
                //no change
                return;
            }
            this.setSaveStatus();
            this.weeklyTestRecord.employee_id = this.employeeId;
            this.setWeeklyTestSubjectRecord(this.weeklyTestRecord)
            try {
                await this.postWeeklyTestSubjectRecord();
                this.setSaveStatus('saved');
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.setSaveStatus('error');
            }
        },

        setSaveStatus (saveState = 'saving') {
            Object.keys(this.save).forEach(key => {
                if(key === saveState){
                    this.save[key] = true;
                    return;
                } 
                this.save[key] = false;
            })
        }
    }
}
</script>

<style scoped>
    /*Remove spinners in WebKit browsers (Chrome, Safari, etc.) */
    ::v-deep input[type=number]::-webkit-inner-spin-button, 
    ::v-deep input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none; 
        margin: 0; 
    }

    /* Firefox */
    ::v-deep input[type=number] {
        appearance: textfield;
        -webkit-appearance: none;
        -moz-appearance: textfield;
        text-align: center;
    }
</style>